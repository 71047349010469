@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: "Hiragino-Sans";
  src: local('Hiragino-Sans'), url('./assets/hiragino.ttc');
}

:root {
  --bg-color: #11141a;
  --text-color: #fff;
  --hero-bg-color: #475569;
  --header-bg-color: rgba(17, 20, 26, 0.5)
}

* {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-padding-top: 2rem;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  background: var(--bg-color);
  color: var(--text-color);
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 3rem;
  background: var(--header-bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

h2 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 6rem;
}

.logo {
  font-size: 25px;
  color: #fff;
  text-decoration: none;
  font-size: 2.3rem;
  font-weight: 600;
  cursor: pointer;
}

nav a {
  margin-left: 35px;
  color: #fff;
  text-decoration: none;
  font-size: 1.7rem;
  font-weight: 500;
  transition: 0.3s;
}

nav a:hover, nav a.active {
  color: #0ef;
}

nav .nav-link {
  margin-left: 35px;
  color: #fff;
  text-decoration: none;
  font-size: 1.7rem;
  font-weight: 500;
  transition: 0.3s;
  cursor: pointer;
}

nav .nav-link:hover, 
nav .nav-link.active {
  color: #0ef;
}

section {
  height: 100vh;
  /* padding: 10rem 3% 2rem; */
}

section.about,
section.projects,
section.contact {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* section.serenescape {
  background-color: #0B4A58;
} */

section div.content {
  padding: 10rem 3% 2rem;
  font-size: 1.7rem;
}

section div.flexbox {
  display: flex;
  align-items: center;
  gap: 3rem;
  flex-direction: column;
}

section.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

section.apps {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero {
  background: #475569;
  position: relative;
  width: 100%;
  padding-left: 3rem;
}

.hero h1 {
  font-size: 5.6rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}

.hero img {
  display: none;
}

.social-links a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
  background: transparent;
  border: 0.2rem solid #0ef;
  border-radius: 50%;
  color: #0ef;
  margin: 3rem 1.5rem 3rem 0;
  transition: 0.5s ease;
}

.social-links a:hover {
  background: #0ef;
  color: #FFF;
  box-shadow: 0 0 1rem #0ef;
}


p {
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
}

.contact h2 {
  margin-bottom: 3rem;
}

.contact form {
  max-width: 70rem;
  margin: 1rem auto;
  text-align: center;
  margin-bottom: 3rem;
}

.contact form .input-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact form .input-box input, .contact form textarea {
  width: 100%;
  padding:1.5rem;
  font-size: 1.6rem;
  color: var(--text-color);
  background-color: var(--hero-bg-color);
  border-radius: 0.8rem;
  border-width: 0;
  margin: 0.7rem 0;
}

.contact form input[type="button"] {
  background-color: #0ef;
  border: 0;
  padding: 1rem 3rem;
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3rem;
}

.slide-up-animate {
  animation-name: slideFadeUp;
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.fade-up-animate {
  animation-name: fade-up;
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.slide-up-portfolio-boxes {
  animation-name: slideFadeUp;
  animation-duration: calc(0.4s * var(--i));
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

/* About */


/* Portfolio */

.portfolio-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.portfolio-container .portfolio-item {
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.portfolio-item img {
  /* width: 20vw; */
  opacity: 1;
  transition: 0.3s ease;
}

.portfolio-item:hover img {
  opacity: 0.5;
  transform: scale(1.1);
}

.portfolio-item .portfolio-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), var(--text-color)); */
  transform: translateY(100%);
  transition: 0.5s ease;
}

.portfolio-layer h4 {
  font-size: 1.5rem;
}

.portfolio-layer .learn-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  text-decoration: none;
  width: 40px;
  height: 40px;
  background: #0ef;
  border-radius: 50%;
  margin-top:3rem;

}

.portfolio-item:hover .portfolio-layer {
  transform: translateY(0);
}

.app-banner {
  position: relative
}

.app-banner .serenescape-link {
  position: absolute;
  left: 30px;
  bottom: 20px;
  color: #FFF;
  display: block;
  background: #333;
  border-radius: 7px;
  border: 1px solid #FFF;
  text-decoration: none;
  font-size: 1.7rem;
  /* font-weight: bold; */
  padding: 7px 15px;
}

.app-banner img {
  width: 100%;
  height: auto;
}



@media only screen and (min-width: 900px) {
  .hero h1 {
    text-align: left;
  }
  .hero img {
    display: block;
    width: 300px;
    height: 300px;
    position: absolute;
    right: 20px;
    top: -50px;
    border-radius: 50%;
    -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
  }

  section.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
  }

  .social-links {
    margin-left: 3%;
  }

  section div.flexbox {
    display: flex;
    align-items: center;
    gap: 3rem;
    flex-direction: row;
  }
}

@keyframes slideFadeUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}