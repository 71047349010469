.social-links {
    display: flex;
    justify-content: center;
    gap: 4rem;
    margin-top: 3rem;
}

.social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #0ef;
    width: 6rem;
    height: 6rem;
    border: 0.2rem solid #0ef;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.social-links a:hover {
    background: #0ef;
    color: var(--bg-color);
    box-shadow: 0 0 1rem #0ef;
    transform: translateY(-3px);
}

.social-links svg {
    font-size: 2.8rem;
}