.projects {
    padding: 4rem 2rem;
    background-color: var(--bg-color, #121212);
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.project-card {
    position: relative;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 30px rgba(66, 153, 225, 0.3);
    border-color: rgba(66, 153, 225, 0.5);
}

.project-image {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.project-card:hover .project-image img {
    transform: scale(1.05);
}

.project-content {
    padding: 2rem;
}

.project-content h3 {
    margin: 0 0 1.2rem 0;
    font-size: 2rem;
    color: #fff;
    font-weight: 500;
}

.project-content p {
    margin: 0 0 2rem 0;
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.8;
    font-size: 1.6rem;
}

.project-technologies {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
}

.tech-tag {
    background: rgba(66, 153, 225, 0.15);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 1.4rem;
    color: #fff;
    transition: all 0.2s ease;
}

.project-card:hover .tech-tag {
    background: rgba(66, 153, 225, 0.3);
    color: #fff;
}

@media (max-width: 768px) {
    .projects-grid {
        grid-template-columns: 1fr;
    }
    
    .projects {
        padding: 3rem 1rem;
    }

    .project-card {
        margin: 0 1.5rem;
    }
}